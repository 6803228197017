import React, { useRef } from "react";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";
import { useEffect } from "react";
import { navigate } from "gatsby";
import { useApplicationContext } from "../../provider";
import Footer1 from "../components/footer";
import Header1 from "../components/header";
import { isLoggedIn } from "../services/auth";
import { useLocation } from "@reach/router";
import CropImageModal from "../components/CropImageModal";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { upscaleImage, resetUpscaleImage, selectCarouselsState } from "../sagas/carousels/carouselsSlice";
import { postImage, selectImageState } from "../sagas/image/imageSlice";

export default function Layout() {
  const dispatch = useDispatch();
  const { isUpscalingImageSuccess, isUpscalingImageFailed, UpscaleImageResponseDto } = useSelector(selectCarouselsState);
  const { isPostingImageSuccess, PostImageResponse } = useSelector(selectImageState);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
    }
  }, []);

  const [state, setState] = React.useState({
    detailedDescription: "",
  });

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const { applicationState, setApplicationState, checkoutState, setCheckoutState } = useApplicationContext();
  const onGenerateClick = (e) => {
    e.preventDefault();
    setApplicationState({
      ...applicationState,
      detailedDescription: state.detailedDescription,
    });
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const keyword = query.get('keyword');
  const SearchInputRef = useRef(null);
  const SearchBtnRef = useRef(null);

  useEffect(() => {
    if (!!keyword) {
      const timeOut = setTimeout(() => {
        SearchInputRef.current.value = keyword;
        SearchBtnRef.current.click();
      }, 500);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [keyword]);

  const openCropTool = (e) => {
    const imgUri = e.target.dataset.img;
    setPicture(imgUri);
    setCropModalOpen(true);
  }

  const [showAnimationflag, setShowAnimationflag] = React.useState(false);
  const [cropModalOpen, setCropModalOpen] = React.useState(false);
  const [cropper, setCropper] = React.useState(<any />);
  const [picture, setPicture] = React.useState("");

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      uploadCroppedImg(cropper.getCroppedCanvas().toDataURL());
      setCropModalOpen(false);
    }
  };
  const uploadCroppedImg = (base64data) => {
    var real_str = base64data.slice(22);
    setShowAnimationflag(true);

    dispatch({
      type: postImage.type,
      payload: { base64Url: real_str },
    });
  };

  useEffect(() => {
    if (isPostingImageSuccess) UpscaleImage(PostImageResponse.url);
  }, [isPostingImageSuccess, PostImageResponse])

  const [selectedImage, setSelectedImage] = React.useState();
  async function UpscaleImage(image) {
    setSelectedImage(image);
    setShowAnimationflag(true);
    dispatch({
      type: upscaleImage.type,
      payload: { images: [image] }
    });
  }

  useEffect(() => {
    if (isUpscalingImageSuccess) {
      const merchifyArr = [];

      merchifyArr.push({
        id_val: 0,
        original: selectedImage,
        upscaled: UpscaleImageResponseDto.results[0].upscaled,
      });

      localStorage.setItem("mergify", JSON.stringify(merchifyArr));
      localStorage.setItem("job_id", ",,,,,");
  
      setCheckoutState({ ...checkoutState, flow: 'search' });

      dispatch(resetUpscaleImage());
      window.location.href = `${window.location.origin}/select-merch`;
    } else if (isUpscalingImageFailed) {
      setShowAnimationflag(false);
      dispatch(resetUpscaleImage());
      //swal popup that says something went wrong and re-try message
      Swal.fire({
        icon: 'error',
        text: 'Oops! Something went wrong. Please try again.',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) UpscaleImage(selectedImage);
      })
    }
  }, [isUpscalingImageSuccess, isUpscalingImageFailed, UpscaleImageResponseDto])

  const searchBubbles = ['3D', 'Accessory', 'Ads', 'Animal', 'Anime', 'Art', 'Avatar', 'Building', 'Cartoon', 'Cute', 'Drawing', 'Fantasy', 'Food', 'Fun', 'Funny', 'Future', 'Games', 'Graphic Design', 'Icons', 'Illustration', 'Landscape', 'Logo', 'Monogram', 'Nature', 'Painting', 'Pattern', 'Photography', 'Pixel Art', 'Psychedelic', 'Scary', 'Space', 'Sport', 'Unique Style', 'Synthwave', 'Wallpaper'];

  return (
    <>
      <div className="container-main" id="page">
        <Helmet>
          <link href={withPrefix("assets/css/search.css")} rel="stylesheet" />
          <link href={withPrefix("assets/css/bootstrap.min.css")} rel="stylesheet"/>
          <link href={withPrefix("assets/css/brands.min.css")} rel="stylesheet" />
          <link href={withPrefix("assets/css/light.min.css")} rel="stylesheet" />
          <link href={withPrefix("assets/css/regular.min.css")} rel="stylesheet" />
          <link href={withPrefix("assets/css/solid.min.css")} rel="stylesheet" />
          <link href={withPrefix("assets/css/duotone.min.css")} rel="stylesheet" />
          <link href={withPrefix("assets/css/fontawesome.min.css")} rel="stylesheet" /> 
          <link href={withPrefix("assets/css/custom-styles.css")} rel="stylesheet" />
          <link href={withPrefix( "assets/plugins/LC-Lightbox-LITE/css/lc_lightbox.css" )} rel="stylesheet" />
          <link href={withPrefix("assets/plugins/LC-Lightbox-LITE/skins/light.css")} rel="stylesheet" />
          <link href={withPrefix( "assets/plugins/LC-Lightbox-LITE/skins/minimal.css" )} rel="stylesheet" />

          <script src={withPrefix("assets/js/jquery.min.js")} type="text/javascript" />
          <script src={withPrefix("assets/js/popper.min.js")} type="text/javascript" />
          <script src={withPrefix("assets/js/bootstrap.min.js")} type="text/javascript" />
          {/* <script src={withPrefix("assets/js/custom.js")} type="text/ " /> */}
          <script src={withPrefix("assets/js/search.js")} type="text/javascript" />
          <script src={withPrefix("assets/plugins/LC-Lightbox-LITE/js/lc_lightbox.lite.custom.js")} type="text/javascript" />
          <script src={withPrefix("assets/plugins/LC-Lightbox-LITE/lib/AlloyFinger/alloy_finger.min.js")} type="text/javascript" />
        </Helmet>
        <Header1></Header1>
        {/* Content - Main */}
        <main className="content-main">
          <div className="search-block">
            <div className="container">
              <div className="heading-txt">
                <div className="sub-heading">Remixer</div>
                <h3>
                  Edit over 10,000,000 amazing images available through Lexica
                  art:
                </h3>
              </div>
              <div className="search-form">
                <label htmlFor=".search-form">Start typing...</label>
                <div className="input-out">
                  <div className="input-inn">
                    <input
                      type="text"
                      ref={SearchInputRef}
                      name="detailedDescription"
                      className="search-input"
                      placeholder="Search..."
                      onChange={onChange}
                      />
                    <button className="btn btn-blue btn-search" ref={SearchBtnRef} onClick={onGenerateClick} >Search</button>
                  </div>
                  <div className="feature-links">
                    <div className="aside">
                      <ul>
                        {searchBubbles.map((bubble, index) => (
                          <li key={index}>
                            <a>{bubble}</a>
                          </li>                          
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="sml-txt">
                  Click Remix to generate a new image from the original prompt
                  or, Click Merchify to see any AI image on a wide variety of
                  merchandise.
                </div>
                <div className="required-box">
                  <em>
                    <img
                      src={withPrefix("assets/img/icon-info.png")}
                      alt="icon-info"
                    />
                  </em>{" "}
                  We do not filter the images shown.
                </div>
              </div>
              <div
                className="search-results row"
                style={{ display: "grid" }}
              ></div>
              <div className="loading-spin-container">
                <i
                  className="loading-spin fa fa-circle-o-notch fa-spin"
                  style={{ fontSize: 24 }}
                />
              </div>

              <div className="row">
                <button
                  className="btn btn-blue btn-loadmore mx-auto my-4"
                  style={{ display: "none" }}
                >
                  Load More...
                </button>
              </div>

              <div>
                <div id="search-page-invisible-button" onClick={openCropTool} data-img="test" style={{ display: 'none' }}>Invisible Button</div>
                {showAnimationflag && (
                  <div className="new_gif" style={{ display: "flex", top: 0, left: 0 }}>
                    <img
                      src={withPrefix("assets/img/green-little-balls.gif")}
                      className="img-fluid mw_50"
                      alt="loading"
                    />
                  </div>
                )}

                <CropImageModal 
                  cropModalOpen={cropModalOpen} 
                  setCropModalOpen={setCropModalOpen} 
                  picture={picture}
                  cropper={cropper} 
                  setCropper={setCropper}
                  getCropData={getCropData}
                />
              </div>
            </div>
          </div>
        </main>
        {/* Footer */}
        <Footer1></Footer1>
      </div>
    </>
  );
}
