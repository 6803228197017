import * as React from "react";
import "./crop-image.scss";
import Modal from "react-modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { withPrefix } from "gatsby";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: 400,
    maxWidth: '90vw',
    borderRadius: "15px",
    fontFamily: `"Albert Sans", sans-serif`,
    padding: 24,
  },
}

export default function CropImageModal({ cropModalOpen, setCropModalOpen, picture, cropper, setCropper, getCropData, isCircleArea, minCropBoxWidth, minCropBoxHeight }) {
  return (
    <Modal
      id="cropper-modal"
      isOpen={cropModalOpen}
      onRequestClose={() => setCropModalOpen(false)}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={isCircleArea ? 'circle-area': 'square-area'}>
        <h3
          style={{
            display: "inline-block",
            margin: 0,
            padding: 0,
            color: "black",
          }}
        >
          Crop Image
        </h3>
        <span
          style={{ float: "right" }}
          className="close-button"
          onClick={() => setCropModalOpen(false)}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.974388 24.5156L12 13.49L23.0256 24.5156L24 23.5412L12.9744 12.5156L24 1.49001L23.0256 0.515625L12 11.5412L0.974388 0.515625L0 1.49001L11.0256 12.5156L0 23.5412L0.974388 24.5156Z"
              fill="#070703"
            />
          </svg>
        </span>
        <p className="paragraph">Select the {isCircleArea ? 'circle': 'square'} area to edit</p>
        <Cropper
          style={{ width: "100%", height: "400px", overflow: "auto", backgroundPosition: "50% 50%", backgroundRepeat: "no-repeat", backgroundImage: `url(${withPrefix("assets/img/green-little-balls.gif")})`, backgroundSize: 'contain' }}
          aspectRatio={1}
          initialAspectRatio={1}
          src={picture}
          highlight={false}
          viewMode={2}
          scalable={false}
          cropBoxResizable={true}
          movable={false}
          background={false}
          responsive={true}
          zoomOnWheel={false}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={false}
          minCropBoxWidth={minCropBoxWidth ?? 0}
          minCropBoxHeight={minCropBoxHeight ?? 0}
        />
      </div>
      <div style={{ marginTop: "15px" }}>
        <button className="btn modal-btn" onClick={getCropData}>
          Crop
        </button>
        <button
          className="btn reset-btn-color"
          onClick={() => cropper.reset()}
        >
          Reset
        </button>
      </div>
    </Modal>
  )
}
